.bg_images {
    background-repeat: no-repeat;
    background-position: bottom left;
    background-size: cover;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.9)),
        url("../../public/images/bg/bg-her-4.jpg");
    width: 100%;
    display: flex;

    .heading-query {
        margin-top: 50rem;
    }

    .absolute-video {
        width: 100%;
        height: 20%;
        display: flex;
        margin-top: 5rem;
        align-items: center;
        margin-bottom: 10rem;
        border-radius: 20px;
        justify-content: center;
        // background: #0077ff;

        .video {
            width: 100%;
            height: 100%;
            // object-fit: cover;
            cursor: pointer;
            border-radius: 20px;
        }
    }
}

.from-top {
    margin-top: 35rem;
}

.card {
    padding: 0;
    background: #fff;
    object-fit: cover;
    height: 100%;
    overflow: hidden;
    box-shadow: 0px 6px 34px rgba(215, 216, 222, 0.41);

    .img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.google-doc {
    margin-top: 4rem;
    margin-bottom: 4rem;
    // margin-left: 40rem;
}

.books {
    padding: 0;
    width: 180px;
    object-fit: cover;
    background: #fff;
    border: 1px solid #000000;
    box-shadow: 0px 6px 34px rgba(215, 216, 222, 0.41);

    .img {
        width: 100%;
        height: 257px;
        object-fit: cover;
    }

    .content-flex {
        gap: 1px;
        width: 100%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: space-between;

        h4 {
            font-size: 14px;
            margin-bottom: 0;
            cursor: pointer;
        }

        h4:hover {
            color: #0e6ffd;
        }

        p {
            font-size: 11px;
        }
    }
}

.subtitle {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.row-container {
    .row-change {
        gap: 10px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        .container {
            width: 20%;
            a {
                display: flex;
                align-items: center;
                background: #7f7f7f;
                padding: 1.5rem 0;
                border-radius: 5px;
                justify-content: center;
                .text-card {
                    font-size: 16px;
                    font-weight: 500;

                    width: 160px;
                    color: #fff;
                    margin-bottom: 0;
                    text-align: center;
                    word-break: normal;

                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}

@media (max-width: 1169px) {
    .bg_images {
        .heading-query {
            margin-top: -5rem;
        }
    }

    .from-top {
        margin-top: 5rem !important;
    }

    .row-container {
        .row-change {
            .container {
                a {
                    .text-card {
    
                        width: 140px;
                    }
                }
            }
        }
    }
}

@media (max-width: 921px) {
    .media-query {
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;
        background: #fff;
        position: fixed;
        z-index: 99;
        border-right: 1px solid rgba(238, 238, 238, 0.08);
    }

    .bg_images {
        background-repeat: no-repeat;
        background-position: bottom left;
        background-size: cover;
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.9)),
            url("../../public/images/bg/bg-her-4.jpg");
        width: 100%;
        display: flex;

        .heading-query {
            margin-top: -5rem;
        }

        .absolute-video {
            // width: 700px;
            height: 20%;
            display: flex;
            margin-top: 5rem;
            align-items: center;
            margin-bottom: 1rem;
            margin-left: 0;
            border-radius: 20px;
            justify-content: center;
            
            .video {
                width: 100%;
                height: 100%;
                object-fit: cover;
                cursor: pointer;
                border-radius: 20px;
            }
        }

        .bg{
            background-repeat: no-repeat;
            background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.9)), url("../../public/images/gallery/thumail.jpg");
        }
    }

    .from-top {
        margin-top: 5rem !important;
    }

    .row-container {
        .row-change {
            .container {
                a {
                    .text-card {
    
                        width: 100px;
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .media-query {
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;
        background: #fff;
        position: fixed;
        z-index: 99;
        border-right: 1px solid rgba(238, 238, 238, 0.08);
    }

    .bg_images {
        background-repeat: no-repeat;
        background-position: bottom left;
        background-size: cover;
        // background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.9)),
        //     url("../../public/images/bg/bg-her-4.jpg");
        width: 100%;
        display: flex;

        .heading-query {
            margin-top: -5rem;
        }

        .absolute-video {
            // width: 700px;
            height: 20%;
            display: flex;
            margin-top: 5rem;
            align-items: center;
            margin-bottom: 1rem;
            margin-left: 0;
            border-radius: 20px;
            justify-content: center;
            background: transparent;

            .video {
                width: 100%;
                height: 100%;
                object-fit: cover;
                cursor: pointer;
                border-radius: 20px;
            }
        }
    }

    .from-top {
        margin-top: 5rem !important;
    }

    .row-container {
        .row-change {
            .container {
                width: 32%;
                a {
                    .text-card {
    
                        width: 120px;
                    }
                }
            }
        }
    }
}

@media (max-width: 721px) {
    .media-query {
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;
        background: #fff;
        position: fixed;
        z-index: 99;
        border-right: 1px solid rgba(238, 238, 238, 0.08);
    }

    .bg_images {
        background-repeat: no-repeat;
        background-position: bottom left;
        background-size: cover;
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.9)),
            url("../../public/images/bg/bg-her-4.jpg");
        width: 100%;
        display: flex;

        .heading-query {
            margin-top: -5rem;
        }

        .absolute-video {
            // width: 700px;
            height: 20%;
            display: flex;
            margin-top: 5rem;
            align-items: center;
            margin-bottom: 1rem;
            margin-left: 0;
            border-radius: 20px;
            justify-content: center;
            background: transparent;

            .video {
                width: 100%;
                height: 100%;
                object-fit: cover;
                cursor: pointer;
                border-radius: 20px;
            }
        }
    }

    .from-top {
        margin-top: 5rem !important;
    }

    .row-container {
        .row-change {
            .container {
                width: 30%;
                a {
                    .text-card {
    
                        width: 90px;
                    }
                }
            }
        }
    }
}

@media (max-width: 621px) {
    .media-query {
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;
        background: #fff;
        position: fixed;
        z-index: 99;
        border-right: 1px solid rgba(238, 238, 238, 0.08);
    }

    .bg_images {
        background-repeat: no-repeat;
        background-position: bottom left;
        background-size: cover;
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.9)),
            url("../../public/images/bg/bg-her-4.jpg");
        width: 100%;
        display: flex;

        .heading-query {
            margin-top: -5rem;
        }

        .absolute-video {
            // width: 700px;
            height: 20%;
            display: flex;
            margin-top: 5rem;
            align-items: center;
            margin-bottom: 1rem;
            margin-left: 0;
            border-radius: 20px;
            justify-content: center;
            background: transparent;

            .video {
                width: 100%;
                height: 100%;
                object-fit: cover;
                cursor: pointer;
                border-radius: 20px;
            }
        }
    }

    .from-top {
        margin-top: 5rem !important;
    }

    .row-container {
        .row-change {
            .container {
                width: 40%;
                a {
                    .text-card {
    
                        width: 100px;
                    }
                }
            }
        }
    }
}

@media (max-width: 450px) {
    .heading-query {
        font-size: 3.3rem;
    }
    .text-query {
        font-size: 13px;
        line-height: 18px;
    }

    .row-container {
        width: 410px;
        padding-left: 0;
        overflow-x: auto;
        scroll-behavior: smooth;
        // border: 2px solid #000;

        .row-change {
            gap: 0;
            width: 950px;
            overflow-x: auto;
            flex-wrap: nowrap;

            .container {
                width: 100%;
                padding: 1rem 0;
                padding-left: 1rem;
                a {
                    width: 100%;
                    .text-card {
                        font-size: 10px !important;
                    }
                }
            }
        }
    }

    .row-container::-webkit-scrollbar {
        width: 0;
    }
}
